// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("/home/msimons/temp/staging/simons.ac/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lifelines-js": () => import("/home/msimons/temp/staging/simons.ac/src/pages/lifelines.js" /* webpackChunkName: "component---src-pages-lifelines-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/msimons/temp/staging/simons.ac/.cache/data.json")

